import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaLocationArrow,
  FaSignOutAlt,
  FaHome,
  FaBook,
  FaImage,
} from "react-icons/fa";
import image from "../../aron-logo.png"; // Ensure this path is correct

import "./Sidebar.scss";

function Sidebar() {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate("/login");
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar-top">
        <h2 className="admin-panel">Admin Panel</h2>
        <div className="hr-line"></div>
        <nav>
          <div className="section-label">Main</div>
          <ul>
            <li>
              <NavLink to="/admin/dashboard" activeClassName="active">
                <FaHome className="icon" />
                <p className="names-cat">Dashboard</p>
              </NavLink>
            </li>
          </ul>
          <div className="section-label">General</div>
          <ul>
            <li>
              <NavLink to="/admin/add-team-members" activeClassName="active">
                <FaUsers className="icon" />
                <p className="names-cat">Team Members</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-category" activeClassName="active">
                <FaLocationArrow className="icon" />
                <p className="names-cat">Add Designation</p>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/admin/add-album-category" activeClassName="active">
                <FaBook className="icon" />
                <p className="names-cat">Add Album</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-album" activeClassName="active">
                <FaImage className="icon" />
                <p className="names-cat">Add Image</p>
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/admin/add-job" activeClassName="active">
                <FaUsers className="icon" />
                <p className="names-cat">Add Job</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-event" activeClassName="active">
                <FaUsers className="icon" />
                <p className="names-cat">Add Event</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-event-images" activeClassName="active">
                <FaUsers className="icon" />
                <p className="names-cat">Add Event Images</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <button onClick={handleLogout} className="logout-button">
        <FaSignOutAlt className="icon" />
        <span className="logout-name">Logout</span>
      </button>
    </div>
  );
}

export default Sidebar;
