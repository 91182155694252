import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddEventImages.scss";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3003";

function AddEventImages() {
  const [eventId, setEventId] = useState("");
  const [image, setImage] = useState(null);
  const [events, setEvents] = useState([]);
  const [eventImages, setEventImages] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${API_URL}/events`);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      alert("Failed to fetch events. Please try again.");
    }
  };

  const fetchEventImages = async (eventId) => {
    try {
      const response = await axios.get(
        `${API_URL}/event-images/event/${eventId}`
      );
      setEventImages(response.data);
    } catch (error) {
      console.error("Error fetching event images:", error);
      alert("Failed to fetch event images. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("event_id", eventId);
    if (image) {
      formData.append("image", image);
    }

    try {
      await axios.post(`${API_URL}/event-images`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Image added successfully!");
      fetchEventImages(eventId);
    } catch (error) {
      console.error("Error adding event image:", error);
      alert("Failed to add image. Please try again.");
    }
  };

  const handleDelete = async (imageId) => {
    try {
      await axios.delete(`${API_URL}/event-images/${imageId}`);
      fetchEventImages(eventId);
    } catch (error) {
      console.error("Error deleting event image:", error);
      alert("Failed to delete image. Please try again.");
    }
  };

  return (
    <div className="event-image-main-div">
      <div className="add-event-image">
        <h1 className="title-event-image">Add Event Images</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Event:</label>
            <select
              className="input-event-image"
              value={eventId}
              onChange={(e) => {
                setEventId(e.target.value);
                fetchEventImages(e.target.value);
              }}
              required
            >
              <option value="">Select Event</option>
              {events.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.heading}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} />
          </div>
          <button className="button-add-event-image" type="submit">
            Add Image
          </button>
        </form>
      </div>

      <div className="event-images-container">
        <h2 className="title-event-image-result">Event Images</h2>
        {eventImages && eventImages.length > 0 ? (
          eventImages.map((image) => (
            <div key={image.id} className="event-image-card">
              <div className="event-image-info">
                <img
                  className="event-image-preview"
                  src={`${API_URL}/event-images/${image.id}`}
                  alt={`Event ${image.event_id}`}
                />
              </div>
              <div className="event-image-actions">
                <button onClick={() => handleDelete(image.id)}>Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No images found for the selected event.</p>
        )}
      </div>
    </div>
  );
}

export default AddEventImages;
